.contactUs-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  font-weight: 500;
  font-size: 1.1rem;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
}

.contactUs-form-item {
  display: flex;
  flex-direction: column;
}

.contactUs-form-item label {
  font-weight: 500;
  font-size: 1rem;
  word-wrap: break-word;
  word-break: break-word;
}

.contactUs-form input,
.contactUs-form textarea {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #495057;
  font-size: 0.9rem;
}

.contactUs-form-item input,
.contactUs-form-item textarea {
  margin-top: 0.3rem;
}

.submit-button {
  padding: 0.5rem 0;
  background-color: #ffe161;
  color: black;
  border-radius: 0.25rem;
  border: 0;
  font-weight: 600;
  font-size: 1rem;
  width: fit-content;
  padding: 0.5rem 0.8rem;
  margin-left: auto;
  margin-top: 0.5rem;
}

.submit-button:hover {
  background-color: #ffd938;
  cursor: pointer;
}

.submit-button:active {
  transform: scale(0.98);
  animation-timing-function: ease-in;
}

.err_message {
  font-size: 0.8rem;
  color: red;
  margin-bottom: 0.8rem;
}

.success_form,
.error_form {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.125rem;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  width: 50%;
}
.success_form {
  color: #155724;
  border: 1px solid #c3e6cb;
  background-color: #d4edda;
}

.loading {
  width: 100%;
  text-align: center;
}

.error_form {
  color: #721c24;
  border: 1px solid #f5c6cb;
  background-color: #f8d7da;
}

.disabled {
  background-color: #6c757d;
}

.disabled:hover {
  background-color: #6c757d;
}

.disabled:active {
  /* border: 2px solid black; */
  /* box-shadow: 0 2px #495057;
  transform: translateY(2px); */
  transform: scale(1);
  animation-timing-function: ease-in;
}

@media screen and (max-width: 425px) {
  .contactUs-form,
  .success_form,
  .error_form {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .contact-us .title {
    padding: 0 2rem;
  }
}
