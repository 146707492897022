.about {
  padding: 7rem 5rem 0rem 5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #232323;
}

.title {
  font-size: 3rem;
  line-height: 1.1;
  margin-bottom: 2rem;
  width: auto;
  text-align: center;
}

.about-item {
  width: auto;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
}

.about-item:first-of-type {
  margin-bottom: 2rem;
}

.about-item > .item-title {
  font-weight: 600;
}

.item-description {
  font-size: 1.1rem;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 425px) {
  .about {
    padding: 5rem 2rem 0rem 2rem;
  }
}
