.exp-collection {
  display: grid;
  grid-template-columns: 70%;
  grid-gap: 1.8rem;
  justify-content: center;
}

@media screen and (max-width: 425px) {
  .exp-collection{
    grid-template-columns: 85%;
  }
}