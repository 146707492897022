.exp-card-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px 0 #cccccc;
}

.exp-time {
  margin-right: 10px;
  font-size: 1.1rem;
  word-wrap: normal;
  border-right: 2px solid #cccccc;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.exp-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
}

@media screen and (max-width: 600px) {
  .exp-title {
    font-size: 0.8rem;
  }
}
