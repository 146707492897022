.navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 8px 48px;
  position: fixed;
  top: 0;
  min-height: 60px;
  z-index: 10;
}

.navbar > span img {
  height: 5rem;
}

.navbar-icon {
  display: flex;
  flex-direction: row;
}

.navbar-item-group {
  list-style: none;
  display: flex;
}

.navbar-item {
  margin: auto 1em;
}

.navbar-item a {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 6px;
}
.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: white;
}

.bg-transparent .navbar-item a {
  color: white;
}

.bg-white .navbar-item a {
  color: #232323;
}

.navbar-shadow{
  box-shadow: 0 0px 14px #cccccc;
}

@media screen and (max-width: 992px) {
  .navbar {
    flex-direction: column;
  }
  .static{
    position: static;
  }
  .navbar-icon {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-item-group {
    width: 100%;
    flex-direction: column;
    display: none;
  }
  .navbar-item {
    width: 100%;
    display: block;
    text-align: center;
    margin: 0.667em 0;
  }
  .active {
    display: flex;
  }
  .bg-transparent {
    background-color: rgba(1, 1, 1, 0.3);
  }
  
}

@media screen and (max-width: 600px) {
  .navbar {
    padding: 8px 20px;
  }
  .navbar > span img {
    height: 3rem;
  }
}
