.qualification-description {
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
  padding: 0 2rem;
}

.certificate-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}

.certificate-wrapper img {
  height: 450px;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .certificate-wrapper {
    flex-direction: column;
  }
  .certificate-wrapper img {
    width: 200px;
    height: auto;
  }
}
