.hamburger-btn {
  /* position: absolute;
  z-index: 3;
  right: 35px;
  top: 35px; */
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.hamburger-btn .btn-line {
  width: 28px;
  height: 3px;
  margin: 0 0 5px 0;
  transition: all 0.5s ease-out;
}

.line-black .btn-line {
  background: black;
}
.line-white .btn-line {
  background: white;
}

.hamburger-btn.close {
  transform: rotate(180deg);
}

/* Styling the three lines to make it an X */
.hamburger-btn.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-btn.close .btn-line:nth-child(2) {
  opacity: 0;
}

.hamburger-btn.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}
