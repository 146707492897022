.find-us-item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 2rem;
}

.company-info-item{
  margin-top: 2rem;
}

.ci-item-title {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.ci-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.ci-item p{
  font-size: 1.1rem;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
}

#map{
  width: 400px;
  height: 400px;
}

.email-group{
  display: flex;
  flex-direction: column;
}

.email-group a, .ci-item a{
  text-decoration: none;
  color: #ffffff;
}

.email-group a:hover, .ci-item a:hover{
  text-decoration: underline;
}

.ci-item a{
  margin-left: 1rem;
}
.map-info > iframe{
  width: 500px;
  height: 450px;
}

.working-hours .ci-item{
  margin-bottom: 0;
}

.px{
  padding: 0;
}

@media screen and (max-width: 1200px) {
  .company-info{
    width: 30%;
  }
}

@media screen and (max-width: 920px) {
  .find-us-item{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .company-info{
    width: 100%;
    
  }

   .ci-item-title{
    text-align: center;
  }

  .company-info-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .ci-item{
    flex-direction: column;
    align-items: center;
  }

  .ci-item a{
    margin: 0;
  }

  .ci-item svg{
    margin-bottom: 0.5rem;
  }
  .my-1{
    margin-bottom: 2rem;
    margin-top: 0.5rem;
  }
  
}

@media screen and (max-width: 768px) {

  .map-info > iframe{
    width: 400px;
    height: 400px;
  }
  .px{
    padding: 0 2rem;
  }
  
}

@media screen and (max-width: 600px) {

  .map-info > iframe{
    width: 300px;
    height: 300px;
  }

}

@media screen and (max-width: 320px) {
  .map-info > iframe{
    width: 300px;
    height: 300px;
  }
  
}

@media screen and (max-width: 300px) {
  .map-info > iframe{
    width: 250px;    
  }
}

/* Footer Section */

.footer, .copyright {
  margin-top: 3rem;
  background-color: #232323;
  padding: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.1rem;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
}

.copyright{
  flex-direction: row;
  margin: 0;
  padding: 0;
  padding-top: 2rem;
  font-size: 0.9rem;
}

.copyright p:last-child{
  text-align: right;
}

@media screen and (max-width: 600px) {
  .footer{
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}