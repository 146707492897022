.service,
.experience,
.qualification,
.contact-us {
  padding-top: 7rem;
  padding-bottom: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #232323;
  /* padding-left: 5rem;
  padding-right: 5rem; */
}

.service {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pswp-gallery {
  justify-content: center;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  width: 100%;
  gap: 2rem;
}

.pswp-gallery a {
  margin-bottom: 1rem;
}

.pswp-gallery > a > img {
  width: 100%;
  object-fit: cover;
}

.newest-project {
  margin-top: 2rem;
  width: 100%;
}

.project-description {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
}
@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 992px) {
  .pswp-gallery {
    grid-template-columns: 48% 48%;
  }
}

@media screen and (max-width: 600px) {
  .pswp-gallery {
    grid-template-columns: 100%;
  }
  .pswp-gallery > a {
    margin: 0;
  }
  .title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 425px) {
  .service {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
/* 
@media screen and (max-width: 300px) {

} */
