.content {
  background-image: url("../../../../public/images/dokumentasi/pic23.jpg");
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: white;
}

.overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
  background-color: #353535;
  opacity: 0.7;
  min-height: 100vh;
}

.home-text,
.find-us {
  z-index: 1;
  animation-delay: 0.2s;
}

.container {
  position: relative;
  z-index: 1;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 150px 150px;
}

.home-text > h1 {
  font-size: 4.6rem;
  line-height: 1.1;
  margin-bottom: 1rem;
  text-align: left;
}

.home-text > p {
  font-size: 1.1rem;
  text-align: justify;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  width: 50%;
}

.find-us {
  background-color: #ffe161;
  border: 1px solid #ffe161;
  color: #614f00;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
  text-decoration: none;
  width: fit-content;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 600;
  margin: 0.6rem 0;
}

@media screen and (max-width: 425px) {
  .container {
    padding: 0px 30px;
  }
  .home-text > p {
    width: 100%;
  }
}
